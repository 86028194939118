import React from 'react'
import { SearchResult } from '../../utils/use-search/types'
import { Container, Name, Result, ResultLink, Counter, Title } from './styled'

const MAX_RESULT_LENGTH = 400
const MAX_QUERY_LENGTH = 20

type Props = {
  results: SearchResult[]
  query: string
}

const SearchResults: React.FC<Props> = (props) => {
  const { results, query } = props

  const sliceQuery = (query: string) => {
    return query.length > MAX_QUERY_LENGTH
      ? `${query.slice(0, MAX_QUERY_LENGTH)}...`
      : query
  }

  const sliceResult = (result: string) => {
    return result.slice(0, MAX_RESULT_LENGTH)
  }

  return (
    <Container>
      <Title>
        Search results for “{sliceQuery(query)}”
        <Counter>{results?.length || 0} entries</Counter>
      </Title>
      <hr />
      {results?.map((result) => (
        <div key={result.id}>
          <ResultLink to={result.url}>
            <Name>{result.name}</Name>
            <Result>{sliceResult(result.text)}</Result>
            <hr />
          </ResultLink>
        </div>
      ))}
    </Container>
  )
}

export default SearchResults
