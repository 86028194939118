import React, { useEffect, useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { navigate, useLocation } from '@reach/router'
import useSearch from '../utils/use-search'
import SearchResults from '../components/search-results'
import { setSearchQuery } from '../store/config'
import SEO from '../components/seo'
import { removeTrailingSlash } from '../utils/format'
import { AllSearchResults } from './__generated__/AllSearchResults'

const Search: React.FC<PageProps<AllSearchResults, unknown>> = (props) => {
  const searchQuery = useSelector((store) => store.config.searchQuery)
  const [query] = useDebounce(searchQuery, 300)
  const { search, pathname } = useLocation()
  const prevPathname = useRef('')
  const pathnameWithoutTrailingSlash = removeTrailingSlash(pathname)
  const dispatch = useDispatch()

  const node = props.data.allLocalSearchPages.edges[0]?.node
  const publicIndexURL = node?.publicIndexURL
  const publicStoreURL = node?.publicStoreURL
  const results = useSearch({ query, publicIndexURL, publicStoreURL })

  // Saves a search query to a URL
  useEffect(() => {
    void navigate(`/search?query=${query}`, { replace: true })
  }, [query])

  // Restores a search from URL params on history back event
  useEffect(() => {
    const params = new URLSearchParams(search)
    const searchQuery = params.get('query')
    const fromNavigation = pathnameWithoutTrailingSlash !== prevPathname.current

    if (typeof searchQuery === 'string' && fromNavigation) {
      dispatch(setSearchQuery(searchQuery))
    }

    prevPathname.current = pathnameWithoutTrailingSlash
  }, [dispatch, pathnameWithoutTrailingSlash, query, search])

  return (
    <>
      <SEO title="Search" />
      <SearchResults results={results} query={query} />
    </>
  )
}

export default Search

export const query = graphql`
  query AllSearchResults {
    allLocalSearchPages {
      edges {
        node {
          publicIndexURL
          publicStoreURL
        }
      }
    }
  }
`
