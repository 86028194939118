import { useEffect, useState, useMemo } from 'react'
import FlexSearch, { Index } from 'flexsearch'
import { Props, SearchResult } from './types'

const useSearch = (props: Props) => {
  const { publicIndexURL, publicStoreURL, query, options } = props
  const [store, setStore] = useState<Record<string, SearchResult>>()
  const [index, setIndex] = useState('')
  const [flex, setFlex] = useState<Index<string>>()
  const [result, setResult] = useState<string[]>()

  useEffect(() => {
    if (publicIndexURL && publicStoreURL) {
      void fetch(publicIndexURL)
        .then((r) => r.text())
        .then(setIndex)

      void fetch(publicStoreURL)
        .then((r) => r.json())
        .then(setStore)
    }
  }, [publicIndexURL, publicStoreURL])

  useEffect(() => {
    if (!index) return

    const importedIndex = FlexSearch.create<string>({ async: true })
    importedIndex.import(index)

    setFlex(importedIndex)
  }, [index])

  useMemo(() => {
    if (!query || !store || !flex) return []

    void flex.search(query, options).then(setResult)
  }, [flex, options, query, store])

  return result?.map((id) => store?.[id]).filter((r) => r) as SearchResult[]
}

export default useSearch
