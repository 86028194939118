import { Link } from 'gatsby'
import styled from 'styled-components'
import { Body01 } from '../../styles/shared'

export const Container = styled.div``

export const Title = styled.h3`
  display: flex;
  align-items: center;
`

export const Counter = styled.span`
  ${Body01}
  color: ${({ theme }) => theme.palette.gray60};
  padding-left: 8px;
`

export const Name = styled.h3``

export const Result = styled.div``

export const ResultLink = styled(Link)`
  color: inherit;
`
